import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Footer, Heading, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../../components/layout";
// import HomeHero from "../sections/home/HomeHero";

// TODO: #73 IMPLEMENT - StudentSupport - header/hero
// TODO: #74 IMPLEMENT - StudentSupport - banner
// TODO: #75 IMPLEMENT - StudentSupport - how it works
// TODO: #77 IMPLEMENT - StudentSupport - demographics
// TODO: #78 IMPLEMENT - StudentSupport - contact us
// TODO: #79 IMPLEMENT - StudentSupport - you're in good company


const StudentSupport = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Student Support'
    >
      {/* <Announcement 
        containerBackgroundColor='var(--bf-core-teal)'
        textColor='white'
        // copy={data.homeAnnouncement.data.header}
      /> */}
      <Heading h2> header/hero </Heading>
      {/* <HomeHero /> */}
      <HorizontalDivider />
      <Heading h2> banner </Heading>
      <HorizontalDivider />
      <Heading h2> how it works </Heading>
      <HorizontalDivider />
      <Heading h2> demographics </Heading>
      <HorizontalDivider />
      {/* <Heading h2> impact </Heading> */}
      {/* <HorizontalDivider /> */}
      <Heading h2> contact us </Heading>
      <HorizontalDivider />
      <Heading h2> you're in good company </Heading>
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query StudentSupportPageContentQuery {
    
//   }
// `

export default StudentSupport;